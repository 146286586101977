import { useEffect, useState } from 'react';

import './Ielts.scss';
import http from '../../axios.config';

function Ielts({
	ieltsModal,
	setIeltsModal,
	successfulModal,
	setSuccessfulModal,
}) {
	const [isModalOpen, setIsModalOpen] = useState(ieltsModal);

	const handleSubmit = (e) => {
		e.preventDefault();
		const { date } = e.target.elements;

		const userDataJSON = localStorage.getItem('user_data');

		const userData = JSON.parse(userDataJSON);

		userData.ielts = date.value;

		const updatedUserDataJSON = JSON.stringify(userData);

		localStorage.setItem('user_data', updatedUserDataJSON);
		setSuccessfulModal(true);
		setIeltsModal(false);
	};

	const closeModal = () => {
		setIeltsModal(false);
	};

	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	return (
		<>
			{ieltsModal ? (
				<div className='modal_bg' onClick={closeModal}>
					<div className='ielts_wrapper' onClick={stopPropagation}>
						<div
							className='close'
							onClick={() => {
								setIeltsModal(false);
							}}>
							<i className='fa-solid fa-xmark'></i>
						</div>
						<form onSubmit={handleSubmit}>
							<label htmlFor='date'>
								Qachon IELTS imtihonini topshirishni reja qilyapsiz?
							</label>
							<input type='month' id='date' name='date' />
							<button className='add_btn' type='submit'>
								Submit
							</button>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}

export default Ielts;
