import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./ApexChart.scss"; // Импортируйте ваш CSS файл здесь

const ApexChart = ({ stage }) => {
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const chartState = {
    series: [userData.corrects, userData.mistakes],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Correct answers", "Mistakes"],
      colors: ["#4CAF50", "#FF5733"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 450,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div id="chart-container">
      <ReactApexChart
        width={450}
        options={chartState.options}
        series={chartState.series}
        type="donut"
        className="chart"
      />
      <h1 className="chart-center-text">{stage}</h1>
    </div>
  );
};

export default ApexChart;
