import "./Header.scss";
import Logo from "../../assets/images/logo2.png";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="header_wrap">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
