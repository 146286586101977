import React from "react";

const Context = React.createContext();

function Provider({ children }) {
  const [state, setState] = React.useState(
    JSON.parse(window.localStorage.getItem("user_data")) || false
  );

  React.useEffect(() => {
    if (state) {
      window.localStorage.setItem("user_data", JSON.stringify(state));
    } else {
      window.localStorage.removeItem("user_data");
    }
  }, [state]);

  return (
    <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
  );
}

export { Context, Provider };
