import React, { useState, useEffect, useCallback } from "react";
import http from "../../axios.config";
import "./Question.scss";
import Loader from "../../Components/Loader/Loader";
import Header from "../../Containers/Header/Header";
import Footer from "../../Containers/Footer/Footer";
import { useNavigate } from "react-router-dom";

import Video from "../../assets/images/video.mp4";

function Question() {
  const navigate = useNavigate();

  const [questionList, setQuestion] = useState({
    isFetched: false,
    data: [],
    err: false,
  });
  const [questionsList, setQuestions] = useState({
    isFetched: false,
    data: [],
    err: false,
  });
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [level, setLevel] = useState(1);
  const [levelTitle, setLevelTitle] = useState("");
  const [mistakes, setMistakes] = useState(0);
  const [corrects, setCorrects] = useState(0);
  const initialTime = level === 4 ? 5 * 60 : 3 * 60;
  const [seconds, setSeconds] = useState(initialTime);

  const limit = level == 4 ? 10 : 5;

  const resetTimer = useCallback(() => {
    setSeconds(initialTime);
  }, [initialTime]);

  const handleSubmitCallback = useCallback(() => {
    let updatedIncorrectAnswers = 0;
    let updatedCorrectAnswers = 0;

    answers.forEach((e) => {
      questionList.data?.forEach((q) => {
        if (q?.test_id == e?.id) {
          if (q?.test_option_answer != e?.answer) {
            updatedIncorrectAnswers++;
          } else {
            updatedCorrectAnswers++;
          }
        }
      });
    });

    if (answers?.length !== questionList?.data?.length) {
      let b = Number(questionList?.data?.length) - Number(answers?.length);
      updatedIncorrectAnswers += Number(b);
    }

    setIncorrectAnswers(updatedIncorrectAnswers);
    setMistakes((prev) => updatedIncorrectAnswers + prev);
    setCorrects((prev) => updatedCorrectAnswers + prev);
    const foundLevel = questionsList?.data?.find((e) => e.level_id == level);

    if (updatedIncorrectAnswers > Number(foundLevel?.level_mistake_count)) {
      if (level === 1) {
        const foundLevel = questionsList.data?.find((e) => e.level_id == level);
        const userDataJSON = localStorage.getItem("user_data");

        if (userDataJSON) {
          const userData = JSON.parse(userDataJSON);

          userData.level = foundLevel.level_title;
          userData.mistakes = mistakes;
          userData.corrects = corrects;

          const updatedUserDataJSON = JSON.stringify(userData);

          localStorage.setItem("user_data", updatedUserDataJSON);
          navigate("/result");
          console.log(mistakes, corrects);
        }
      } else {
        const foundLevel = questionsList.data?.find(
          (e) => e.level_id == level - 1
        );
        const userDataJSON = localStorage.getItem("user_data");

        if (userDataJSON) {
          const userData = JSON.parse(userDataJSON);

          userData.level = foundLevel.level_title;
          userData.mistakes = mistakes;
          userData.corrects = corrects;

          const updatedUserDataJSON = JSON.stringify(userData);

          localStorage.setItem("user_data", updatedUserDataJSON);
          navigate("/result");
        }
      }
    } else {
      setLevel(level + 1);
      setAnswers([]);
      window.scrollTo(0, 0);
      setIncorrectAnswers(0);
      questionList["isFetched"] = false;
      if (level + 1 === 5) {
        const userDataJSON = localStorage.getItem("user_data");

        if (userDataJSON) {
          const userData = JSON.parse(userDataJSON);

          userData.level = "Intermediate+";
          userData.mistakes = mistakes;
          userData.corrects = corrects;

          const updatedUserDataJSON = JSON.stringify(userData);

          localStorage.setItem("user_data", updatedUserDataJSON);
          navigate("/result");
        }
      }
    }

    resetTimer(); // Сброс таймера
    const form = document.getElementById("myForm");
    form.reset();
  }, [
    answers,
    level,
    mistakes,
    corrects,
    questionList,
    questionsList,
    navigate,
    resetTimer,
  ]);

  useEffect(() => {
    http
      .get(`tests/${level}?limit=${limit}`, {})
      .then((res) => {
        setQuestion({
          isFetched: true,
          data: res.data.data,
          err: false,
        });
        setLevelTitle(res.data.data.level_title);
      })
      .catch((error) =>
        setQuestion({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, [level]);

  useEffect(() => {
    http
      .get(`/levels/list`, {})
      .then((res) => {
        setQuestions({
          isFetched: true,
          data: res.data.data,
          err: false,
        });
      })
      .catch((error) =>
        setQuestions({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timer);
        handleSubmitCallback();
        setSeconds(initialTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, initialTime, handleSubmitCallback]);

  const handleAnswer = (e, test) => {
    const option = e.target.dataset.option;
    const status = e.target.checked;

    if (status) {
      const foundAnswer = answers.find((e) => e.id === test?.test_id);

      if (foundAnswer) {
        foundAnswer["answer"] = option;
      } else {
        setAnswers([...answers, { id: test?.test_id, answer: option }]);
      }
    }
  };

  return (
    <>
      <div className="video-background">
        <video autoPlay loop muted playsInline className="fullscreen-video">
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="content">
          <Header />
          {questionList.isFetched ? (
            <div>
              <div className="question">
                <div className="container">
                  <form onSubmit={(e) => e.preventDefault()} id="myForm">
                    <div className="step_wrap">
                      <h1 className="level">
                        {questionList.data[0]?.level_title}
                      </h1>
                      <div className="stepper_wrapper">
                        <div
                          className={`stepper_item ${
                            level >= 1 ? "completed" : ""
                          }`}
                        >
                          <div className="step_counter">1</div>
                        </div>
                        <div
                          className={`stepper_item ${
                            level >= 2 ? "completed" : ""
                          }`}
                        >
                          <div className="step_counter">2</div>
                        </div>
                        <div
                          className={`stepper_item ${
                            level >= 3 ? "completed" : ""
                          }`}
                        >
                          <div className="step_counter">3</div>
                        </div>
                        <div
                          className={`stepper_item ${
                            level >= 4 ? "completed" : ""
                          }`}
                        >
                          <div className="step_counter">4</div>
                        </div>
                      </div>
                      <h1>
                        {Math.floor(seconds / 60)}:
                        {(seconds % 60).toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                        })}
                      </h1>
                    </div>
                    <p>
                      Ushbu test sizni taxminiy ingliz tili darajangiz belgilab
                      beradi!
                    </p>
                    {questionList.data?.map((test, i) => (
                      <div className="question_wrap" key={i}>
                        <h4>
                          <span>{++i}</span> {test.test_title}
                        </h4>
                        <div className="radio-button-container">
                          <div className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              id={`radio${i}-1`}
                              name={`radio-group-${i}`}
                              data-option={"A"}
                              onClick={(evt) => handleAnswer(evt, test)}
                              disabled={submitted}
                            />
                            <label
                              className="radio-button__label"
                              htmlFor={`radio${i}-1`}
                            >
                              <span className="radio-button__custom"></span>
                              {test.test_option_1}
                            </label>
                          </div>
                          <div className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              id={`radio${i}-2`}
                              name={`radio-group-${i}`}
                              data-option={"B"}
                              onClick={(evt) => handleAnswer(evt, test)}
                              disabled={submitted}
                            />
                            <label
                              className="radio-button__label"
                              htmlFor={`radio${i}-2`}
                            >
                              <span className="radio-button__custom"></span>
                              {test.test_option_2}
                            </label>
                          </div>
                          <div className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              id={`radio${i}-3`}
                              name={`radio-group-${i}`}
                              data-option={"C"}
                              onClick={(evt) => handleAnswer(evt, test)}
                              disabled={submitted}
                            />
                            <label
                              className="radio-button__label"
                              htmlFor={`radio${i}-3`}
                            >
                              <span className="radio-button__custom"></span>
                              {test.test_option_3}
                            </label>
                          </div>
                        </div>
                        <div className="line"></div>
                      </div>
                    ))}
                    <button onClick={handleSubmitCallback} disabled={submitted}>
                      Tekshirish
                    </button>
                  </form>
                </div>
              </div>
              <Footer />
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
}

export default Question;
