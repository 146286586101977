import React, { useState } from "react";
import Logo from "../../assets/images/logo3.png";
import "./Home.scss";
import { useNavigate } from "react-router-dom";
import useToken from "../../Hooks/useToken";

import Video from "../../assets/images/video.mp4";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";

function Home() {
  const [formData, setFormData] = useState({ name: "", number: "" });
  const [errorModal, setErrorModal] = useState(false);
  const [, setToken] = useToken();
  const [phoneNumber, setPhoneNumber] = useState("+998");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const isFormInvalid =
    formData.name === "" || formData.surname === "" || phoneNumber.length < 17;
  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, surname, number } = event.target.elements;

    const data = {
      name: name.value + " " + surname.value,
      phone_number: phoneNumber,
      ielts: null,
    };

    setToken(data);
    navigate("/test");
  };

  const formatPhoneNumber = (input) => {
    // Remove non-digit characters from input
    const digitsOnly = input.replace(/\D/g, "");

    // Format the phone number as per +XXX-XX-XXX-XX-XX pattern
    let formattedNumber = "+";
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 5 || i === 8 || i === 10) {
        formattedNumber += "-";
      }
      formattedNumber += digitsOnly[i];
    }
    return formattedNumber;
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);
    setPhoneNumber(formatted);
  };

  return (
    <>
      <div className="video-background">
        <video autoPlay loop muted playsInline className="fullscreen-video">
          <source src={Video} type="video/mp4" />
          {/* You can add multiple source elements for different video formats */}
          Your browser does not support the video tag.
        </video>
        <ErrorModal
          errorModal={errorModal}
          setErrorModal={setErrorModal}
          text={"Ism Familiyangizni toliq kiriting"}
        />
        <div className="content">
          <div className="home">
            <div className="container">
              <div className="home_wrap">
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="text">
                  <h1>
                    Bilimingizni sinab ko'ring va{" "}
                    <span className="red">sertifikatga</span> ega bo'ling!
                  </h1>
                  <p>
                    Myschool o‘quv markazining daraja aniqlash testiga xush
                    kelibsiz!
                  </p>
                  <p className="text_p">
                    Ingliz tili darajangizni bilish uchun avvalam bor ro‘yxatdan
                    o‘ting va shu orqali sertifikat qo‘lga kiriting!
                  </p>
                </div>
                <form
                  autoComplete="off"
                  className="home_form"
                  onSubmit={handleSubmit}
                  name="disable-autocomplete"
                >
                  <div className="form_wrap">
                    <div className="form__group field">
                      <input
                        autoComplete="off"
                        type="text"
                        id="name"
                        className="form__field"
                        name="name"
                        placeholder=""
                        required
                        onChange={handleInputChange}
                      />
                      <label htmlFor="name" className="form__label">
                        Ismingiz
                      </label>
                    </div>
                    <div className="form__group field">
                      <input
                        autoComplete="off"
                        type="text"
                        id="surname"
                        className="form__field"
                        name="surname"
                        placeholder=""
                        required
                        onChange={handleInputChange}
                      />
                      <label htmlFor="surname" className="form__label">
                        Familiyangiz
                      </label>
                    </div>
                    <div className="form__group field">
                      <input
                        autoComplete="new-password"
                        type="tel"
                        id="number"
                        className="form__field"
                        placeholder=""
                        required
                        name="number"
                        value={phoneNumber}
                        maxLength={17}
                        onChange={handlePhoneNumberChange}
                      />
                      <label htmlFor="number" className="form__label">
                        Telefon raqamingiz
                      </label>
                    </div>
                    <button
                      className="home_btn"
                      disabled={isFormInvalid}
                      type="submit"
                    >
                      Testni boshlash!
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
