import React, { useState, useEffect } from "react";
import "./Result.scss";
import SertifikatBeginner from "../../assets/images/Beginner-1.png";
import SertifikatElementary from "../../assets/images/Elementary-1.png";
import SertifikatPreinter from "../../assets/images/Pre-intermediate-1.png";
import SertifikatInter from "../../assets/images/intermediate-1.png";
import Ielts from "../../Containers/Ielts/Ielts";
import Error from "../../Containers/Error/Error";
import Successful from "../../Components/Successful/Successful";
import Footer from "../../Containers/Footer/Footer";
import http from "../../axios.config";
import SuccessModal from "./SuccessModal/SuccessModal";
import Loader from "../../Components/Loader/Loader";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Video from "../../assets/images/video.mp4";
import ApexChart from "../../Components/ApexChart/ApexChart";

function Result() {
  const [ieltsModal, setIeltsModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDataJSON = localStorage.getItem("user_data");

  if (!userDataJSON) {
    return <Error />;
  }

  const userData = JSON.parse(userDataJSON);

  if (!userData.level || !userData.name || !userData.phone_number) {
    return <Error />;
  }

  const name = userData.name;
  const level = userData.level;

  const sertifikatImage =
    level === "Beginner"
      ? SertifikatBeginner
      : level === "Elementary"
      ? SertifikatElementary
      : level === "Pre-inter"
      ? SertifikatPreinter
      : level === "Intermediate+"
      ? SertifikatInter
      : "";

  const today = new Date();
  const day = today.getDate().toString().padStart(2, "0");
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${day}.${month}.${today.getFullYear()}`;

  const handleSubmit = (e) => {
    setLoading(true); // Устанавливаем состояние загрузки в true

    const data = {
      name: userData.name,
      phone: userData.phone_number,
      level: userData.level,
      correct_answers: userData.corrects,
      mistake_answers: userData.mistakes,
      ielts_date: userData.ielts,
    };

    http
      .post("student/add", data, {})
      .then((response) => {
        if (response.data.status === 200) {
          setSuccessModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Устанавливаем состояние загрузки обратно в false после завершения запроса
      });
  };

  const stage =
    level === "Beginner"
      ? "A1"
      : level === "Elementary"
      ? "A2"
      : level === "Pre-inter"
      ? "B1"
      : level === "Intermediate+"
      ? "B2"
      : "";

  // const downloadPDF = () => {
  //   const capture = document.querySelector(".sertifikat");
  //   html2canvas(capture).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "landscape",
  //       unit: "mm",
  //       format: "a4",
  //     });

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     const imgWidth = pdfWidth;
  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     const offsetY = (pdfHeight - imgHeight) / 2;

  //     pdf.addImage(imgData, "PNG", 0, offsetY, imgWidth, imgHeight);
  //     pdf.save("certificate.pdf");
  //   });
  // };

  const downloadImage = () => {
    const capture = document.querySelector(".sertifikat");
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Создаем ссылку для скачивания изображения
      const a = document.createElement("a");
      a.href = imgData;
      a.download = "certificate.png"; // Установите желаемое имя файла
      a.style.display = "none";
      document.body.appendChild(a);

      // Запускаем скачивание
      a.click();

      // Удаляем ссылку из DOM
      document.body.removeChild(a);
    });
  };

  return (
    <>
      <div className="video-background">
        <video autoPlay loop muted playsInline className="fullscreen-video">
          <source src={Video} type="video/mp4" />
          {/* You can add multiple source elements for different video formats */}
          Your browser does not support the video tag.
        </video>

        <div className="content">
          <div className="result">
            {/* {loading && <Loader />}{' '} */}
            {/* Показываем Loader, если состояние загрузки true */}
            <Ielts
              ieltsModal={ieltsModal}
              setIeltsModal={setIeltsModal}
              successfulModal={successfulModal}
              setSuccessfulModal={setSuccessfulModal}
            />
            <Successful
              successfulModal={successfulModal}
              setSuccessfulModal={setSuccessfulModal}
            />
            <SuccessModal
              setSuccessModal={setSuccessModal}
              successModal={successModal}
              downloadPDF={downloadImage}
            />
            <div className="result_wrap" style={{ marginBottom: "30px" }}>
              <div className="container">
                <h1>
                  Hurmatli {name}! Sizning bilim darajangiz {level}
                </h1>
              </div>
            </div>

            <div className="container">
              <ApexChart stage={stage} />
              <p
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  color: "red",
                  marginBottom: "30px",
                }}
              >
                Eslatib o‘tamiz bu sertifikat hech qanday qonuniy kuchga ega
                emas!
              </p>
              <div className="sertifikat" style={{ marginBottom: "20px" }}>
                <img src={sertifikatImage} alt="" />
                <h3>{name}</h3>
                <h4>{formattedDate}</h4>
              </div>

              <p style={{ textAlign: "center" }}>
                Ingliz tili bilan professional tarzda shug‘ullanmoqchi
                bo‘lsangiz MY School ga murojaat qiling va filiallarimizga
                tashrif buyuring!
              </p>
              <div className="result_btns">
                <button
                  className="ielts"
                  onClick={() => {
                    setIeltsModal(true);
                  }}
                >
                  IELTS topshirmoqchimisiz?
                </button>
                <button className="complete" onClick={handleSubmit}>
                  Yakunlash
                </button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
