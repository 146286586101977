import React from "react";
import "./Successful.scss";

const Successful = ({ successfulModal, setSuccessfulModal }) => {
  return (
    <>
      {successfulModal ? (
        <div className="successful">
          <div className="successful__icon">
            <i class="fa-regular fa-circle-check "></i>
          </div>
          <div className="successful__title">Muvaffaqiyatli yakunlandi</div>
          <div
            className="successful__close"
            onClick={() => setSuccessfulModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 20 20"
              height="20"
            >
              <path
                fill="#393a37"
                d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Successful;
