import { Routes, Route } from "react-router-dom";
import useToken from "./Hooks/useToken";

import "./Hooks/useToken";
import Home from "./Pages/Home/Home";
import Question from "./Pages/Question/Question";
import Result from "./Pages/Result/Result";
import Error from "./Containers/Error/Error";

function App() {
  const [token] = useToken();

  if (token) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<Question />} />
        <Route path="/result" element={<Result />} />
        <Route path="*" element={<Error />} />
      </Routes>
    );
  } else {
    return <Home />;
  }
}

export default App;
