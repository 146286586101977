import React, { useEffect } from "react";
import { useSpring } from "@react-spring/core";
import { useNavigate } from "react-router-dom";
import { animated } from "@react-spring/web";
import { Link } from "react-router-dom";

import "./Error.scss";

const Error = () => {
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));
  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const newX = (clientX / window.innerWidth) * 2 - 1;
      const newY = (clientY / window.innerHeight) * 2 - 1;
      set({ xy: [newX, newY] });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [set]);

  const handleSubmit = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="wrapper">
      <div className="box">
        <div className="box__ghost">
          {/* Symbols */}
          <div className="symbol symbol1"></div>
          <div className="symbol symbol2"></div>
          <div className="symbol symbol3"></div>
          <div className="symbol symbol4"></div>
          <div className="symbol symbol5"></div>
          <div className="symbol symbol6"></div>

          <div className="box__ghost-container">
            <animated.div
              className="box__ghost-eyes"
              style={{
                transform: xy.interpolate(
                  (x, y) => `translate(${x * 25}px, ${y * 25}px)`
                ),
              }}
            >
              <div className="box__eye-left"></div>
              <div className="box__eye-right"></div>
            </animated.div>
          </div>
          <div className="box__ghost-bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="box__description">
          <div className="box__description-container">
            <div className="box__description-title">Whoops!</div>
            <div className="box__description-text">
              Siz qidirgan sahifani topa olmadik
            </div>
          </div>

          <button className="box__button" onClick={handleSubmit}>
            Orqaga qaytish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Error;
