import Logo from "../../assets/images/logo2.png";
import "./Footer.scss";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_wrap">
          <div className="footer_left">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <p className="copyright">
              Copyright © 2023{" "}
              <a href="https://t.me/jakhongirov_1" target="_blank">
                SlashTeam
              </a>{" "}
              All Rights Reserved.
            </p>
          </div>
          <div className="footer_right">
            <h4>Call center</h4>
            <p>+998 55 517 77 71</p>
            <div className="social_medias">
              <a href="tel:+998555177771" className="social_media">
                <i className="fa-solid fa-phone"></i>
              </a>
              <a
                href="https://www.instagram.com/myschooluz/"
                className="social_media"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://t.me/MyschooL_Uz_Bot"
                target="_blank"
                className="social_media"
              >
                <i className="fa-brands fa-telegram"></i>
              </a>
            </div>
            <p className="copyright2">
              Copyright © 2023{" "}
              <a href="https://t.me/jakhongirov_1" target="_blank">
                SlashTeam
              </a>{" "}
              All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
