import React, { useEffect, useState } from "react";
import "./ErrorModal.scss";

const ErrorModal = ({ errorModal, setErrorModal, text }) => {
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (errorModal) {
      const id = setTimeout(() => {
        setErrorModal(false);
      }, 3000);

      setTimeoutId(id);
    } else {
      clearTimeout(timeoutId);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [errorModal, setErrorModal, timeoutId]);
  return (
    <>
      {errorModal ? (
        <div className="error_modal">
          <div className="error_modal__icon">
            <i className="fa-solid fa-exclamation"></i>
          </div>
          <div className="error_modal__title">{text}</div>
          <div
            className="error_modal__close"
            onClick={() => setErrorModal(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ErrorModal;
