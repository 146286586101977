import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./SuccessModal.scss";

function SuccessModal({ successModal, setSuccessModal, downloadPDF }) {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    navigate("/");
    localStorage.clear();
  };

  return (
    <>
      {successModal ? (
        <div className="modal_bg">
          <div className="succes_wrapper">
            <div className="check">
              <i className="fa-regular fa-circle-check"></i>
            </div>
            <h1 className="success_h1">
              Sertifikatingizni bizning filiallarimizdan olib ketishingiz
              mumkun!
            </h1>
            <div className="btns">
              <button className="download" onClick={downloadPDF}>
                Sertifikatni yuklab oling <i class="fa-solid fa-download"></i>
              </button>
              <button className="add_btn" onClick={handleSubmit}>
                Orqaga qaytish
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default SuccessModal;
